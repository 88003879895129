import React from 'react'

import Layout from '../components/Layout'

const AboutPage = () => (
  <Layout currentPath="/about" title="About">
    <h1>About</h1>
    <p>
      Born in Liverpool, Peter Philip began his working life as a Lithographic
      Artist, while studying part time at Liverpool College of Art, eventually
      returning, full-time to formal art studies, graduating with an honours
      degree in Fine Art from Liverpool John Moores University. He now regularly
      exhibits his work around the North West of England.
    </p>
    <p>
      He is a representational painter working mainly in oils depicting subjects
      that are part of everyday life. Inspired by traditional classical
      paintings he paints contemporary subjects firmly establishing his
      compositions in the present day. An adept draughtsman preliminary studies
      forming an important element in the execution of his paintings, he also
      makes bold and articulate finished drawings.
    </p>
    <p>
      Prices depend on the size and complexity of the work. Paintings ranging
      from £250 to £2000, drawings £200 to £800, and etchings from £30 to £100.
      Please feel free to contact him for information concerning artwork or
      proposed commissions.
    </p>
    <h3>
      Peter Philip Retrospective Exhibition, July 2015 at Williamson Art Gallery
    </h3>
    <p>
      <iframe
        src="https://www.youtube.com/embed/Y14_ly_lMG0"
        height="315"
        width="560"
        allowfullscreen=""
        frameborder="0"
        title="Peter Philip Retrospective Exhibition, July 2015 at Williamson Art Gallery"
      />
    </p>
  </Layout>
)

export default AboutPage
